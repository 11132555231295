import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import ChevronDownIcon from "src/ui-treact/components/svgs/ChevronDownIcon.js";
import FlavourCard from './FlavourCard'

const StyledDiv = styled.div`
max-width: 1200px;

margin: 0 auto;
background: white;
margin-bottom: 60px;
`
const Column = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl`w-11/12 relative`;
const FAQ = styled.div`
${tw`text-center px-8 py-3 sm:py-3 -mt-1 text-gray-800 transition duration-300 cursor-pointer select-none sm:px-10  hover:text-gray-900 border`}
background-color:#fff;
&:hover{
  background-color:#fff
} 
`;  
const Question = tw.dt`flex justify-center items-center`;
const QuestionText = tw.span`text-base lg:text-base font-normal`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300 absolute right-0 mx-4`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const ImageListContainer = motion.custom(styled.dd`
background-color:white;
display:flex;
flex-direction: row;
flex-wrap: wrap;
justify-content:center;
align-content: center;
align-items: center;
${tw`pointer-events-none text-sm sm:text-base leading-relaxed`}

`);

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        faqs: [
          {
            question: "Ingredients & Supplement Facts",
            type: "image",
            headin1:"Original Soups",
            urls1:[
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
            ],
            headin2:"New Soup Flavors",
            urls2:[
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
                "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
            ]
          },
          {
            question: "What's Inside Video",
            type: "video",
            urls:[
                "https://www.youtube.com/embed/uxph2gwo3qw",
            ]
          },
        ],
       ...finalData
  }  
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <StyledDiv>
        <Column>
          <FAQSContainer>
            {content.faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <ImageListContainer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                {faq.type == 'image' ?
                    <>
                        <FlavourCard urls={faq.urls1} heading={faq.headin1} divider/>
                        <FlavourCard urls={faq.urls2} heading={faq.headin2}/>
                    </>
                    :
                faq.type == 'video' ? 
                // <video width="320" height="240" tabindex="-1" controlslist="nodownload">
                // <source src={faq.urls[0]} type="video/mp4" />
                // </video>
                <iframe src={faq.urls[0]}
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowfullscreen
                title='video'
                width='100%'
                height='540px'
                />
                : null}
                </ImageListContainer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
    </StyledDiv>
  );
};
