import React from 'react';
import tw, {styled} from "twin.macro";
import TextCard from './TextCard';
 
const StyledDiv = styled.div`
  width: 95%;
  background: transparent;
  display: flex;
  justify-content: around;
  align-content: center;
  self-alig: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px auto;
`;
 

const TextCardList = ({ data, page}) => {
  // console.log('BuyCardsList', data)
  let finalData = data?.schema_json;
  let content = {
    cards: [
        {
            id: 1,
            heading: 'What’s in the Kit',
            description: '<strong>Original Soup Flavors:</strong> Vegetable, Minestrone, Vegetable Quinoa, Mushroom, Tomato.<br /><strong>— or—</strong><br /><strong>New Soup Flavors:</strong> Butternut Squash, Black Bean, Butternut Squash with Quinoa, Spinach with White Bean, Tomato.<br /><br /><strong>Your kit also includes: </strong>L-bar (two flavors: nut-based and choco crisp), Olives, Kale Crackers, Tea (Spearmint, Hybiscus), L-Drink, Supplements.<br /><br />',
            background_color: "#fefcf6",
            border_color: "#fd6e0d",
            remember_text: "Remember: you can change the order of what you eat, but don’t switch components from one day to the other—each day’s intake is carefully crafted to maximize the benefits of your FMD experience."
        },
        {
            id: 2,
            heading: 'One of the best ways to experience fasting!',
            description: 'Only ProLon supports your mind and body with delicious food for energy, to fight cravings, and rejuvenate you from the inside out!<br /><br /><strong>With ProLon, you get everything you need pre-packaged and ready to go. Each kit comes with five boxes, which are labeled by day so you know what to eat each day.</strong> <br /><br />That means no last-second trips around town trying to find unusual ingredients, and no long cook times. Just open, heat, and eat!',
            background_color: "#4caf5008",
            border_color: "#9bc93c",
            remember_text: ""
        }
    ],
    ...finalData,
  };
  const cards = content?.cards?.map((card) => {
    return (
      <TextCard
        key={card.id}
        heading={card.heading}
        description={card.description}
        border_color={card.border_color}
        background_color={card.background_color}
        remember_text={card.remember_text}
      />
    );
  });
  return <StyledDiv tw="" >{cards}</StyledDiv>;
};

export default TextCardList;
