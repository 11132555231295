import React, { useState } from 'react';
import styled from 'styled-components'
import _ from 'lodash';
import Store from 'src/stores/common-store';
import FlavourAccord from './FlavourAccord';
import TextCardList from './TextCardList';
import ImageOnText from './ImageOnText';
import 'twin.macro'
import AnnouncementBar from './AnnouncementBar';
import QAccordion from './QAccordion';

const StyledDiv = styled.div`
max-width: 1200px;

margin: 0 auto;
background: #fafafa;
  .select-box {
    border: 1px solid lightgray;
  }
  .btn-selected {
    background-color: #fd6e0d;
    color: #fff;
    border-radius: 13px;
  }
  .btn-normal {
   background-color: #fff;
   color: #000;
   border: 1px solid #edf2f7;
 }
  .Add-to-cart {
    background-color: #fd6e0d;
  }
  .btn-flav {
    border: 1px solid lightgray;
    background-color: ${props => props.originalSelected ? '#fff' : '#fd6e0d'};
    color: ${props => props.originalSelected ? '#000' : '#fff'};
  }
  .mail-box {
    border-bottom: 2px solid black;
  }
  .mail-box input:focus {
    border: none;
  }
  footer {
    background-color: #fffcfc;
  }
.img-class {
   @media (min-width:767px) {
      max-width: 550px;
   }
}
button:focus {outline:0;}
select:focus {outline:0;}

.major-banner-coaching {
   background: #9acb34;
   color: black;
   padding: 5px 10px;
   font-size: 15px;
    margin: 0 0 15px 0;
 }

`


export default function BuyProlon(props) {
   let { productVariants } = props;
   productVariants = productVariants?.map((e) => {
      let { selectedOptions } = e;
      if (selectedOptions && selectedOptions.length > 0) {
         selectedOptions.forEach((option, i) => {
            e[`option${i + 1}`] = option.value;
         });
      }
      return e;
   });

   const Option1Array = _.uniqBy(productVariants, 'option1').map(e => e.option1);

   const [flavour, setflavour] = useState(Option1Array[0]);
   const [Option2Array, setOption2Array] = useState(productVariants?.filter(e => e.option1 == flavour));
   const [plan, setplan] = useState(Option2Array && Option2Array[0].option2);
   const [price, setprice] = useState(Option2Array && Option2Array[0].price);
   const [compareAtPrice, setcompareAtPrice] = useState(Option2Array && Option2Array[0].compareAtPrice);
   const [selectedImage, setselectedImage] = useState(Option2Array && Option2Array[0].image.originalSrc)

   const option1 = Option1Array.map((option, i) => {
      return (
         <button key={i} tw="text-sm px-5 py-2 mx-1" className={`${flavour == option ? 'btn-selected' : 'btn-normal'}`}
            onClick={() => {
               setflavour(option)
               changeOption2(option)
            }}
         >{option}</button>
      )
   })

   const option2 = Option2Array?.map((option, i) => {
      let addon = null
      if (option?.option2.includes("Subscri")) {
         addon = <p tw="md:ml-3 text-xs p-1" style={{background: '#fec91c'}}>(Best Value & Greatest Results)</p>
      }
      if (option?.option2.includes("Fast Bar Bundle")) {
         addon = <p tw="md:ml-3 text-xs p-1" style={{background: '#fff'}}>(1 Prolon + 30 intermittent Fasting Bars)</p>
      }
      
      let subscription = null
      if (option?.option2.includes("Subscri")) {
         subscription =
            <div className="select-box" tw="px-1 py-1 flex md:ml-3 ">
               <select name="" id=""
                  onChange={(e) => {
                     let { name, value } = e.target;

                  }}
               >
                  <option>Deliver every month</option>
                  <option>Deliver every 3 months</option>
               </select>
            </div>
      }

      return (
         <div tw="mt-3 ml-4 flex flex-1 justify-between items-center w-11/12 ">
            <div tw="flex justify-end content-start items-start">
               <input tw="mt-2" type="radio" name='radio1' id={'varient' + i}
                  checked={plan == option.option2 ? true : false}
                  onClick={() => {
                     setplan(option.option2);
                     setprice(option.price)
                     setcompareAtPrice(option.compareAtPrice)
                     setselectedImage(option.image.originalSrc)
                  }}
               />
               <div tw="flex flex-col justify-end items-start">
                  <label tw="text-base font-bold md:pl-3" for={'varient' + i}>{option.option2}</label>
                  {addon}
                  {subscription}
               </div>
            </div>

            <div tw="text-base flex flex-1 justify-end">
               <p tw="text-base line-through">{option.compareAtPrice}</p>
               <p tw="text-base text-red-600 pl-2 font-semibold">{option.price}</p>
            </div>
         </div>
      )
   })

   const changeOption2 = (option) => {
      let newArray = productVariants?.filter(e => e.option1 == option)
      setOption2Array(newArray);
      // console.log('optionSelected', plan)

      let optionSelected = _.find(newArray, { 'option2': plan });

      // console.log('optionSelected', optionSelected)
      setprice(optionSelected.price)
      setcompareAtPrice(optionSelected.compareAtPrice)
      setselectedImage(optionSelected.image.originalSrc)
   }
   const [adding, setadding] = useState(false);
   let finalData = props.data?.schema_json;

   return (
      <StyledDiv id={finalData?.section_id}>
         <div tw="px-1 md:px-12 mt-4 md:mt-8 flex flex-col md:flex-row md:justify-around items-start">
            <div tw="flex flex-col px-1 md:mb-auto w-full md:w-1/2">
               <img className="img-class img1" src={selectedImage} tw="w-full" />
               {/* {(plan?.includes("Subscri") || plan?.includes("Single Box Order")) && <img className="img-class img2" src="https://cdn.shopify.com/s/files/1/0126/2921/3243/files/25Off_Fresh_Start_540x.png?v=1609967233" tw="pt-3 w-full" />} */}
            </div>
            {/* Content Section  */}
            <div tw="flex flex-col items-start md:items-start w-full md:w-1/2  px-1 md:pl-16">
            <p class="major-banner-coaching">All ProLon purchase include a 20 minute health coach consultation.</p>
               <h1 tw="-mt-2 text-3xl font-bold">ProLon®</h1>
               <div tw="flex items-center mt-2">
                  <p tw="line-through text-lg">${compareAtPrice}</p>
                  <p tw="text-lg px-2">${price}</p>
                  <p tw="text-xs pl-2 text-red-600 md:text-lg md:font-semibold">Save ${compareAtPrice - price}</p>
               </div>
               <div tw="mt-2">
                  {option1}
               </div>
               {
                  flavour == Option1Array[0] ? <p tw="text-left text-sm md:text-base mt-3">
                     <i tw="font-bold not-italic">New Soup Flavors: </i>
                Butternut Squash, Black Bean, Butternut Squash Quinoa, Spinach & White Bean, Tomato
            </p>
                     :
                     flavour == Option1Array[1] ? <p tw="text-left text-sm md:text-base mt-3">
                        <i tw="font-bold not-italic">Soups: </i>
            Vegetable, Minestrone, Vegetable Quinoa, Mushroom, Tomato
            </p>
                        : null
               }

               {option2}
               <button
                  tw="px-8 py-3 mb-10 text-white text-sm font-normal mt-10 md:px-8" className={`Add-to-cart ${adding ? 'btn--loading' : ''}`}
                  onClick={async () => {
                     setadding(true);
                     let subscription = plan?.includes("Subscri") ? true : false
                     let data = Store.getDataFromVarient({
                        variants: productVariants,
                        option1: flavour,
                        option2: plan,
                        free_gift: false,
                        quantity: 1,
                        subscription: subscription
                     })
                     await Store.addItem(data);
                     Store.showCart()
                     setadding(false);

                  }}
               >
                  {adding ? 'Adding...' : 'ADD TO CART'}
               </button>
            </div>
         </div>
         <QAccordion />
      </StyledDiv>
   )
}
