import React, {useState} from 'react'
import styled from 'styled-components'

const StyledDiv =styled.div`


.accordion-item--opened .accordion-item__icon {
  transform: rotate(180deg);
}
.accordion-item--opened .accordion-item__inner {
  max-height: 200rem;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 0.5s;
  transition-property: max-height;
}
.accordion-item--opened .accordion-item__content {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity, transform;
}
.accordion-item__line {
  display: block;
  padding: 0.8rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  z-index: 2;
  position: relative;
  background: transparent;
  cursor: pointer;

}
.accordion-item__title {
  font-size: 1.0rem;
  margin: 0;
  font-weight: 400;
  color: #121212;
  background: transparent;
}
.accordion-item__icon {
  width: 1.2rem;
  height: 1.2rem;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
  opacity: 0.6;
  position: absolute;
  right: 30px;
}
.accordion-item__inner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 0.5s;
  transition-property: max-height;
  z-index: 1;
  position: relative;
}
.accordion-item__content {
  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-delay: 0.5s;
  padding: 0 1.2rem 1.2rem;
}

`
export default function AccordionItem({title, item}) {
    const [opened, setopened] = useState(false) 
    return (
        <StyledDiv>
            <div 
            {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
        }}
        >
        <div {...{ className: 'accordion-item__line',
                 onClick: () => { setopened(!opened)}
               }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {title}
          </h3>
          <span {...{ className: 'accordion-item__icon' }}/>
        </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div>
                {item}
            </div>
          </div>
            </div>

      </StyledDiv>
    )
}
