import React from 'react'
import styled from 'styled-components'
import AccordionItem from './AccordionItem'
import FlavourCard from './FlavourCard'

const StyledDiv = styled.div`
width: 100%;
background: #fafafa;
display:flex;
justify-content:center;
align-content: center;
align-items: center;
flex-direction: column;
margin-top: 50px;
margin-bottom: 50px;


.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  max-width: 95%;
  width: 95%;
  border-radius: 0.1rem;
  overflow: hidden;
  // box-shadow: 0 0 0.8rem 0.1rem rgba(15, 72, 179, 0.06), 0 20px 30px -10px rgba(15, 72, 179, 0.2);
  padding-top: 5px;

}
.accordion-list__item {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: -1px;
}

`
export default function QAccordion() {

  const  topImagesUrls= [
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
    "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
]

const  bottomImagesUrls=[
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
  "https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ingredients_one.jpg?v=1599645390",
]
  const imagesItem = <>
                      <FlavourCard urls={topImagesUrls} heading="Original Soups" divider/>
                      <FlavourCard urls={bottomImagesUrls} heading="New Soup Flavors"/>
                     </>

  const videoItem = <iframe src="https://www.youtube.com/embed/uxph2gwo3qw"
                      frameBorder='0'
                      allow='autoplay; encrypted-media'
                      allowfullscreen
                      title='video'
                      width='100%'
                      height='540px'
                      />


      const data = [
        {
          title: 'Ingredients & Supplement Facts',
          item:imagesItem
        },
        {
          title: `What's Inside Video`,
          item:videoItem
        }
      ]
    return (
      <StyledDiv>
        <ul {...{ className: 'accordion-list' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>

        </StyledDiv>
    )
}
