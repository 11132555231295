import React from "react";
import tw, {styled} from "twin.macro";
import { Link } from "../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general.js";
import { Link as LinkScroll} from 'react-scroll'; 


const Container = styled(LinkScroll)`
  ${tw`w-full bg-white pt-0 `}
  :hover{
    cursor: pointer;
  }
`;

const HeroContainer = styled.div`
${tw`relative z-10 w-full mx-auto right-0`}
overflow:hidden;
max-height:580px;

`

const Heading = styled.h1`
  ${tw`z-20 text-2xl leading-none text-center text-white lg:text-right sm:text-3xl lg:text-4xl xl:text-5xl`} 
  font-weight:700;
  span {
    ${tw`inline-block mt-4`}
  }
`; 
const WrapperAbsolute = styled.div`
  ${tw`z-30 flex items-center justify-end p-5`} 
  ${(props)=>props.center_all &&  tw`flex flex-col  items-center justify-center`}

  width:100%;
  height:100%;
  position:absolute;
  top:0px; 
 ${props=>props.custom_css}
`; 

const PrimaryButton = styled.div`
${tw`text-white px-16 py-3 mx-1 mt-3 md:mt-6 text-sm font-medium transition duration-300 shadow sm:text-base sm:mt-1 sm:px-16 sm:py-3 hover:no-underline focus:shadow-outline`}
background-color:${
  props=>props.background_color
};
color:${
  props=>props.text_color
};
`

const OpacityOverlay = tw.div`z-20 absolute inset-0 bg-black opacity-50`;

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        heading: "What will you<br />benifit from?",
        open_new_tab:false,
        background_image:"//cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_For_Me_-_Ornage_1512x.png?v=1600324658",
        center_all:false,
       ...finalData
  }  

  const primaryActions = content?.buttons?.map((button) =>{
    return(
       <PrimaryButton
        href={button.open_new_tab ? button.primary_button_url : null}
        to={button.open_new_tab ? null : button.primary_button_url}
        target="_blank"
        background_color={button.background_color}
        text_color={button.text_color}
        >
          {button.primary_button_text}
        </PrimaryButton>
    )
})
// console.log('content?.buttons?', content?.buttons)
return (
    <Container data-href={content.url} to={content?.url?.replace('#', '')}>
      <HeroContainer>
          {/* <img src={props?.content?.background_image} alt=""/> */}
        <img src={content.background_image} alt=""></img>
        <OpacityOverlay />
        <WrapperAbsolute center_all={content.center_all} custom_css={content.custom_css}>
            <Heading>
                <div dangerouslySetInnerHTML={{__html:content.heading}}></div>
            </Heading> 
            {primaryActions}
        </WrapperAbsolute>
      </HeroContainer>
    </Container>
  );
};
